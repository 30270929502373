import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SmsConsent } from '@cco/model';

export const userProfileActions = createActionGroup({
  source: 'UserProfile',
  events: {
    componentOpened: emptyProps(),
    getRequiredAttributesSuccess: props<{ requiredAttributeNames: string[] }>(),
    getRequiredAttributesFailure: props<{ err: any }>(),
    refreshTokensSuccess: emptyProps(),
    refreshTokensFailure: props<{ err: any }>(),
    removeEmail: props<{ sub: string }>(),
    removeEmailSuccess: emptyProps(),
    removeEmailFailure: props<{ err: any }>(),
    removePhoneNumber: props<{ sub: string }>(),
    removePhoneNumberSuccess: emptyProps(),
    removePhoneNumberFailure: props<{ err: any }>(),
    sendEmailVerificationCode: props<{ sub: string }>(),
    sendEmailVerificationCodeSuccess: emptyProps(),
    sendEmailVerificationCodeFailure: props<{ err: any }>(),
    smsConsent: props<{ sub: string, smsConsent: SmsConsent }>(),
    smsConsentSuccess: emptyProps(),
    smsConsentFailure: props<{ err: any }>(),
    upsertEmail: props<{ sub: string, email: string }>(),
    upsertEmailSuccess: emptyProps(),
    upsertEmailFailure: props<{ err: any }>(),
    upsertPhoneNumber: props<{ sub: string, phoneNumber: string, smsConsent: SmsConsent }>(),
    upsertPhoneNumberSuccess: emptyProps(),
    upsertPhoneNumberFailure: props<{ err: any }>(),
    verifyEmail: props<{ sub: string, code: string }>(),
    verifyEmailSuccess: emptyProps(),
    verifyEmailFailure: props<{ err: any }>(),
    verifyPhoneNumber: props<{ sub: string, code: string }>(),
    verifyPhoneNumberSuccess: emptyProps(),
    verifyPhoneNumberFailure: props<{ err: any }>(),
  },
});
